<template>
    <div class="home">
        <HeaderContent></HeaderContent>
        <div class="layout-footer standard-sizes">
            <h2>Seleziona operazione:</h2>
            <router-link to="/terminal-browser">
                <v-btn
                    color="blue-grey"
                    prepend-icon="map"
                    size="x-large"
                    rounded="pill"
                    block
                >
                    Settori
                </v-btn>
            </router-link>
            <v-btn
                color="blue-grey"
                prepend-icon="event"
                size="x-large"
                rounded="pill"
                block
                :class="{'loading': loading && appState.operation === 'move'}"
                @click="handleOperationSelection('work-orders')"
                :disabled="loading"
            >
                Ordini di uscita
            </v-btn>
            <router-link to="/ddt-list">
              <v-btn
                  color="blue-grey"
                  prepend-icon="draw"
                  size="x-large"
                  rounded="pill"
                  block>
                FIRMA DDT
              </v-btn>
            </router-link>
            <v-btn
                color="blue-grey"
                prepend-icon="open_with"
                size="x-large"
                rounded="pill"
                :class="{'loading': loading && appState.operation === 'move'}"
                @click="handleOperationSelection('move')"
                :disabled="loading"
                block
            >
                spostamento
            </v-btn>
            <v-btn
                color="blue-grey"
                prepend-icon="library_add"
                size="x-large"
                rounded="pill"
                @click="handleOperationSelection('multi-move')"
                :disabled="loading"
                block
            >
                censimento
            </v-btn>
            <v-btn
                color="blue-grey"
                prepend-icon="exit_to_app"
                append-icon="workspaces"
                size="x-large"
                rounded="pill"
                @click="handleOperationSelection('vehicle-exit')"
                :disabled="loading"
                block
            >
                file di carico
            </v-btn>
            <hr/>
            <v-btn
                color="warning"
                prepend-icon="power_input"
                size="x-large"
                rounded="pill"
                @click="handleFlowChange(2)"
                block
            >
                identifica veicolo
            </v-btn>
        </div>
    </div>
</template>

<script>

import {useAppState} from "@/stores/app-flow";
import HeaderContent from "@/components/HeaderContent.vue";

export default {
    name: 'HomeView',
    components: {
        HeaderContent
    },
    data: () => ({
        loading: false
    }),
    setup() {
        const appState = useAppState();
        return {appState}
    },
    mounted() {
        const st = this.appState;
        st.resetVin();
        st.resetVinSearchResults();
        st.resetOperation();
        st.resetSectorChoice();
        st.resetLaneChoice();
        st.emptyVinSequence();
        st.setMultipleVinSelections(false);
    },
    methods: {
        handleOperationSelection(type) {
            this.appState.setOperation(type);
            this.appState.setFlow(1);
            if (type === "work-orders") {
                this.$router.push('/work-orders');
            }
            else if (type === 'multi-move')
            {
                this.appState.fetchSectors();
                this.appState.setMultipleVinSelections(true)
                this.$router.push('/operation-options');
            } else {
                this.$router.push('/manual-vin-selection');
            }
        },
        handleFlowChange(val) {
            this.appState.setFlow(val);
            this.$router.push(val === 1 ? '/' : '/manual-vin-selection');
        }
    }
}
</script>

<style scoped>
small {
    display: block;
}
</style>

<template>
    <div class="home authentication">
        <HeaderContent></HeaderContent>
        <div class="layout-footer standard-sizes">
            <h2>Accedi:</h2>
            <v-text-field
                :class="'field-default'"
                v-model="email"
                :rules="emailRules"
                autocomplete="email"
                label="Email"
                required
                :disabled="loading"
            ></v-text-field>
            <v-text-field
                :class="'field-default _no-spacing'"
                v-model="password"
                :rules="passwordRules"
                :type="showPassword ? 'text' : 'password'"
                label="Password"
                required
                :disabled="loading"
            >
                <template v-slot:append>
                        <v-icon
                            @click="showPassword = !showPassword">{{ showPassword ? 'visibility_off' : 'visibility' }}</v-icon>
                </template></v-text-field>

            <div class="fake-link"
                 @click="forgotPassword">
                Password dimenticata?
            </div>
            <v-btn
                color="warning"
                size="x-large"
                rounded="pill"
                block
                :disabled="!email || !password"
                :loading="loading"
                v-on:click="submit"

            >
                Accedi
            </v-btn>
        </div>
    </div>
</template>

<script>
import {useAppState} from "@/stores/app-flow";
import HeaderContent from "@/components/HeaderContent.vue";
import {defaultEmailRules, defaultPasswordRules} from "@/utils/default-input-rules";
import {inject} from "vue";

export default {
    name: "AuthenticationView",
    components: {HeaderContent},
    data() {
        return {
            email: "",
            password: "",
            loading: false,
            showPassword: false,
            emailRules: defaultEmailRules,
            passwordRules: defaultPasswordRules
        };
    },
    props: {
    },
    setup() {
        const appState = useAppState();
        const coreService =  inject('coreService');
        const authService =  inject('authService');
        return {appState, coreService, authService}
    },
    methods: {
        forgotPassword() {
            if (this.loading) return;
            this.$router.push('/auth/forgot-password');
        },
        async submit() {
            if (this.loading) return;
            this.loading = true;
            try {
                await this.authService.login(this.email, this.password)
                this.loading = false;
                window.location.assign('/#/home');
                window.location.reload();
            } catch (e) {
                console.log(e);
                if (e.response.status === 401) {
                    this.appState.addNotification({
                        type: 'ERROR',
                        description: 'Credenziali errate'
                    });
                } else {
                    this.appState.addNotification({
                        type: 'ERROR',
                        description: 'Errore durante il login'
                    });
                }
            }
            this.loading = false;

        }
    },
};
</script>
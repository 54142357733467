<template>
    <div :class="{home: true, 'scrollable': positionEnabled || inspectionResult === 'damage'}">
        <HeaderContent></HeaderContent>
        <div class="layout-footer standard-sizes">
            <div v-if="appState.operation === 'vehicle-received'">
                <h2>Danni rilevati:</h2>
                <v-btn-toggle
                    v-model="inspectionResult"
                    mandatory
                    rounded="xl"
                    block
                    :disabled="loading"
                >
                    <v-btn value="prestine">No</v-btn>
                    <v-btn icon="mdi-format-align-center" value="damage">Si</v-btn>
                </v-btn-toggle>
            </div>
            <div>
                <v-switch
                    v-model="positionEnabled"
                    label="Spostamento effetuato"
                    color="orange-darken-3"
                    hide-details
                    v-if="appState.operation === 'vehicle-received'"
                    :disabled="loading"
                ></v-switch>
                <h2
                    v-if="appState.operation !== 'vehicle-received'"
                >
                    Posizione:
                </h2>

                <div class="loadable-select" v-if="appState.operation !== 'vehicle-received' || this.positionEnabled">
                    <span></span>
                    <v-progress-circular
                        model-value="20"
                        color="red"
                        v-if="appState.sectorsLoading"
                        indeterminate></v-progress-circular>
                    <v-select
                        :items="appState.sectors"
                        v-model="appState.sectorChoice"
                        item-title="label"
                        item-value="id"
                        item-props="itemProps"
                        label="Settore"
                        :disabled="appState.sectorsLoading || loading"
                    ></v-select>
                </div>

                <div class="loadable-select" v-if="appState.operation !== 'vehicle-received' || this.positionEnabled">
                    <span></span>
                    <v-progress-circular
                        model-value="20"
                        color="red"
                        v-if="appState.lanesLoading"
                        indeterminate></v-progress-circular>
                    <v-autocomplete
                        label="Fila"
                        item-title="label"
                        v-model="appState.laneChoice"
                        :items="appState.lanes"
                        return-object
                        :disabled="initialised && (appState.lanesLoading || !appState.sectorChoice || loading)"
                    ></v-autocomplete>
                </div>

                <VueDatePicker
                    v-model="appState.vehicleArrivedAt"
                    :auto-apply="true"
                    :clearable="false"
                    locale="it"
                    format="dd/MM/yyyy"
                    :disabled="dateDisabled"
                    v-if="appState.operation === 'move'"
                ></VueDatePicker>

                <div class="regular-select" v-if="appState.operation === 'move'">
                    <span></span>
                    <v-select
                        :items="appState.transportTypes"
                        v-model="appState.selectedTransportType"
                        item-title="label"
                        item-value="value"
                        label="Mezzo"
                        :disabled="arrivedByDisabled || loading"
                    ></v-select>
                </div>

                <v-textarea
                        :class="{'notes': true}"
                        v-if="commentsEnabled"
                        counter
                        label="Note"
                        maxlength="120"
                        v-model="comment"
                        :disabled="loading"
                ></v-textarea>
            </div>
            <div class="d-flex justify-space-between">
                <v-btn
                    icon="arrow_back_ios_new"
                    color="warning"
                    class="btn_icon"
                    :disabled="loading"
                    @click="handleBackAction()"
                ></v-btn>
                <v-btn
                    color="warning"
                    size="x-large"
                    append-icon="workspaces"
                    rounded="pill"
                    @click="handleViewAction()"
                    :disabled="disableSubmit"
                    :class="{'flex-grow-1': true, loading}"
                >
                    Conferma
                </v-btn>
            </div>
        </div>
    </div>
</template>
<script>
import {useAppState} from "@/stores/app-flow";

import HeaderContent from "@/components/HeaderContent.vue";
import VueDatePicker from '@vuepic/vue-datepicker';
import {generateLanes, getVehicleLabel} from "@/utils";
import {inject} from "vue";

export default {
    name: 'OperationOptions',
    components: {
        HeaderContent,
        VueDatePicker
    },
    data: () => {
      return {
          dateDisabled: false,
          arrivedByDisabled: false,
          loading: false,
          commentsEnabled: false,
          inspectionResult: 'prestine',
          damageDetails: {
              note: ''
          },
          positionMandatory: false,
          positionEnabled: false,
          positionDetails: {
              sector: null,
              lane: null
          },
          sectors: [],
          comment: '',
          lanes: [],
          sectorsLoading: false,
          lanesLoading: false,
      }
    },
    computed: {
        sectorChoice() {
            return JSON.stringify(this.appState.sectorChoice);
        },
        disableSubmit() {
            return this.loading ||
                ((this.appState.operation === 'vehicle-received' && this.positionEnabled) ||
                (this.appState.operation !== 'vehicle-received' ? !this.appState.laneChoice || !this.appState.sectorChoice : false) ||
                (this.appState.operation === 'move' && (!this.appState.selectedTransportType || !this.appState.vehicleArrivedAt)));
        }
    },
    watch: {
        sectorChoice(newValue) {
            if (!newValue) {
                this.appState.setLanes([]);
                this.appState.resetLaneChoice();
            } else {
                this.handleSectorChange(newValue);
            }
        }
    },
    setup() {
        // eslint-disable-next-line
        const coreService = inject('coreService');
        const appState = useAppState();
        return {appState, coreService}
    },
    mounted() {
        if (!['multi-move'].includes(this.appState.operation) || this.appState.sectors.length === 0) this.fetchSectors();
        if (!this.appState.operation) this.$router.push('/');
        if (['move'].includes(this.appState.operation)) {
            this.appState.checkVinArrivalDate();
            this.appState.checkVinArrivedBy();
        }

        if (this.appState.vehicleHasArrived) {
            this.dateDisabled = true;
        }
        if (this.appState.vehicleHasArrivedBy) {
            this.arrivedByDisabled = true;
        }
    },
    methods: {
        handleBackAction() {
            if (this.appState.flow === 2) {
                this.appState.resetOperation();
                this.$router.push('/operation-selection-view');
            } else {
                this.appState.resetVin();
                this.$router.push('/home');
                this.appState.selectedVehicle = null;
            }
        },
        async handleViewAction() {
            this.appState.setComment('');
            if (this.commentsEnabled && this.comment) {
                this.appState.setComment(this.comment);
            }

            if (['multi-move', 'realignment'].includes(this.appState.operation)) {
                this.$router.push('/manual-vin-selection');
            } else {
                this.loading = true;
                if (!this.appState.vehicleHasArrived && this.appState.operation === 'move') {
                    if (!this.appState.vehicleArrivedAt) {
                        this.appState.setVehicleArrivalDate(new Date());
                    }
                    await this.coreService.setVehicleArrivalDate(this.appState.selectedVehicle.id, this.appState.vehicleArrivedAt);
                }
                const options = {
                    "sectorId": this.appState.laneChoice.sector,
                    "laneNumber":  this.appState.laneChoice.laneNumber
                };

                if (this.appState.comment) {
                    options.note = this.appState.comment;
                }

                try {
                    await this.coreService.submitVehicleOperation(this.appState.selectedVehicle.id, this.appState.operation, options)
                        .then((res) => {
                            if (res.status < 300) {
                                this.appState.addNotification({
                                    type: 'SUCCESS',
                                    operation: this.appState.operation,
                                    vin: getVehicleLabel(this.appState.selectedVehicle),
                                });
                                this.appState.resetSectorChoice();

                                if (this.appState.flow === 1) {
                                    this.appState.resetVin();
                                    this.appState.selectedVehicle = null;
                                    this.$router.push('/manual-vin-selection');
                                }
                                else {
                                    this.appState.resetOperation();
                                    this.$router.push('/operation-selection-view');
                                }
                            } else if(res.status === 409) {
                                this.appState.setLaneConflict(true);
                                this.appState.setFlow(1);
                                this.appState.setMultipleVinSelections(true);
                                this.appState.setOperation('realignment');
                                this.$router.push('/manual-vin-selection');
                            }
                        });
                } catch (error) {
                    console.log(error);
                }
                this.loading = false;
            }
        },
        fetchSectors() {
            this.appState.fetchSectors(this.appState.operation === 'multi-move');
        },
        handleSectorChange(newValue) {
            newValue = newValue.replaceAll("\"", '')
            const sector = this.appState.sectors.find((sector) => {
                return sector.id == newValue
            });

            if (sector?.label.toLowerCase() === 'jolly') {
                this.commentsEnabled = true;
            } else {
                this.commentsEnabled = false;
            }

            let sectors = [sector];
            if (sector?.type === 'group') {
                sectors = sector.sectors
            }
            this.appState.resetLaneChoice();

            this.generateLanes(sectors);
        },
        generateLanes(sectors) {
            generateLanes(sectors, this.appState, this.coreService)
            this.appState.fetchSectorLanesAvailability(sectors.map((sector) => sector.id));
        }
    }
}
</script>
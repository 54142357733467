// configService.js
import axios from 'axios';
import {inject} from "vue";

export const configService = {
    getConfig() {
        return axios.get('/config.prod.json');
    }
};
export const useConfig = () => {
    const config = inject('$config');
    console.log('config retrieved', config)
    if (!config) {
        console.error('Configuration not provided!');
    }

    return config;
}
<template>
  <div class="main-app-container">
    <UserPanel v-if="shouldShowUserPanel"/>
    <NotificationsManager></NotificationsManager>
    <router-view/>
  </div>
</template>

<style lang="scss">
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  font-family: Roboto, Avenir, Helvetica, Arial, sans-serif;
}
@import "vuetify/dist/vuetify.css";
@import "./style/style";
</style>
<script >
import NotificationsManager from "@/components/NotificationsManager.vue";
import {useAppState} from "@/stores/app-flow";
import UserPanel from "@/components/UserPanel.vue";
import {AuthService} from "@/services/auth.service";
import {CoreService} from "@/services/core.service";
import {inject} from "vue";
export default {
    components: {
        UserPanel,
        NotificationsManager
    },
    provide() {
        return {
            appState: this.appState,
            authService: this.authService,
            coreService: this.coreService,
        }
    },
    setup() {
        const appState = useAppState();
        const config = inject('$config');
        console.log('found config', config);
        const authService = new AuthService(config.baseApiUrl);
        const coreService = new CoreService(config.baseApiUrl);
        return {appState, authService, coreService}
    },
    computed: {
        shouldShowUserPanel() {
            const data = localStorage.getItem("USER_DATA");
            return !!data;
        }
    },
    async mounted() {
        const state = this.appState;

        window.addEventListener('keyup', function(ev) {
            let letterNumber = /^[0-9a-zA-Z]+$/;
            if (ev.key.length === 1 && ev.key.match(letterNumber)) state.addInputValue(ev.key);
        });
    }
}
</script>
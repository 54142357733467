export const defaultEmailRules = [
    value => {
        if (value) return true

        return 'E-mail richiesta.'
    },
    value => {
        if (/.+@.+\..+/.test(value)) return true

        return 'E-mail non valida.'
    },
];

export const defaultPasswordRules = [
    value => {
        if (value) return true

        return 'Password richiesta.'
    }
];
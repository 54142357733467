<template>
  <div class="work-orders-list">

      <div class="lists-container">
          <div class="loader-overlay" v-if="appState.workOrdersLoading">
              <v-progress-circular indeterminate></v-progress-circular>
          </div>
          <v-list>
              <v-list-subheader>{{listHeading}}</v-list-subheader>
              <WorkOrderListItem v-for="workOrder in appState.workOrders" :workOrder="workOrder" v-bind:key="workOrder.id"></WorkOrderListItem>
          </v-list>

          <v-pagination
              v-model="appState.workOrdersPage"
              :length="appState.workOrdersPagesTotal"
              rounded="circle"
              :total-visible="3"
              :disabled="appState.workOrdersLoading"
          ></v-pagination>
      </div>
      <div class="layout-footer standard-sizes">
          <div class="d-flex justify-start align-center footer-bottom-controls">
              <v-btn
                  icon="arrow_back_ios_new"
                  color="warning"
                  class="btn_icon"
                  @click="goBack()"
              ></v-btn>
<!--              <span class="ml-2">{{listHeading}}</span>-->
          </div>
      </div>
  </div>
</template>

<script>
import {useAppState} from "@/stores/app-flow";
import WorkOrderListItem from "@/components/WorkOrderListItem.vue";
import {inject} from "vue";

export default {
    name: "WorkOrdersList",
    components: {
      WorkOrderListItem
    },
    computed: {
        listHeading() {
            return 'Ordini di uscita'
        },
        workOrdersPage() {
            return this.appState.workOrdersPage;
        },
    },
    data() {
        return {
        };
    },
    props: {
    },
    setup() {
        const appState = useAppState();
        const coreService =  inject('coreService');
        appState.fetchWorkOrders();
        return {appState, coreService}
    },
    watch: {
        workOrdersPage() {
            this.appState.fetchWorkOrders();
        }
    },
    methods: {
      goBack() {
          this.appState.currentWorkOrder = null;
          this.$router.push('/home');
      }
    },
};
</script>
<template>
    <div class="home">
        <HeaderContent></HeaderContent>
        <div class="layout-footer standard-sizes">
            <h2>Recupera password:</h2>
            <v-text-field
                :class="'field-default _no-spacing'"
                v-model="email"
                :rules="emailRules"
                label="Email"
                autocomplete="email"
                v-if="!submitted"
                required
                :disabled="loading"
            ></v-text-field>
            <h5 v-else class="subtitle">
                {{message}}
            </h5>
            <div class="fake-link"
                 v-if="!submitted"
                 @click="backToLogin">
                Torna al login
            </div>
            <v-btn
                color="warning"
                size="x-large"
                rounded="pill"
                block
                :disabled="!email"
                :loading="loading"
                v-if="!submitted"
                v-on:click="onSubmit"
            >
                Invia
            </v-btn>
            <v-btn
                color="warning"
                size="x-large"
                rounded="pill"
                block
                v-else
                v-on:click="backToLogin"
            >
                Torna al login
            </v-btn>
        </div>
    </div>
</template>

<script>
import {useAppState} from "@/stores/app-flow";
import HeaderContent from "@/components/HeaderContent.vue";
import {defaultEmailRules} from "@/utils/default-input-rules";
import {inject} from "vue";

export default {
    name: "ForgotPasswordView",
    components: {HeaderContent},
    data() {
        return {
            email: "",
            loading: false,
            message: "Link per il reset della password è stato inviato alla tua email.",
            submitted: false,
            emailRules: defaultEmailRules,
        };
    },
    props: {
    },
    setup() {
        const appState = useAppState();
        const authService =  inject('authService');
        return {appState, authService}
    },
    methods: {
        backToLogin() {
            if (this.loading) return;
            this.$router.push('/auth/login');
        },
        onSubmit() {
            if (this.loading) return;
            this.loading = true;
            this.authService.resetPassword(this.email)
                .then(() => {
                    // this.$router.push('/auth/login');
                    this.loading = false;
                    this.submitted = true;
                })
                .catch(() => {
                    this.loading = false;
                })
        }
    },
};
</script>
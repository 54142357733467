import {v4} from 'uuid';

export const validateVin = (val) => {
    return /^(?=.*[0-9])(?=.*[a-zA-Z])([a-zA-Z0-9]+)$/.test(val) && val.length === 17;
}
/** clean this code **/
export const generateLanes = async (sectors, state, service) => {

    let lanes = [];
    let processes = 0;
    state.setLaneGenerationLoading(true);

    sectors.forEach((sector) => {
        if (!sector) {
            state.setLanes(lanes);
            return;
        }
        let laneNumber = sector.numerationStart || (sector.numerationType === 'Even' ? 2 : 1);
        service.fetchSectorAnomalies(sector.id)
            .then((response) => {
                    let anomalies = response;
                    const sectorLanes = [];
                    for(let i = 1; i <= sector.laneCount; i++) {
                        sectorLanes.push(
                            {
                                label: laneNumber,
                                laneNumber: laneNumber,
                                sector: sector.id,
                                uuid: v4(),
                            }
                        )
                        sector.numerationType === 'Both' ? laneNumber++ : laneNumber += 2;
                    }
                    anomalies.forEach((anomaly) => {
                        if (anomaly.anomalyType === 'SkipLane') {
                            let highest = sectorLanes.length - 1;

                            sectorLanes.splice(highest, 1);
                        }
                        else if (anomaly.anomalyType === 'SkipLaneAndSkipNumber') {
                            let foundIndex = sectorLanes.findIndex((lane) => lane.laneNumber === anomaly.laneNumber);
                            if (foundIndex > -1) sectorLanes.splice(foundIndex, 1);
                        }
                    })
                    lanes = lanes.concat(sectorLanes);
                    processes++;

                    if (processes === sectors.length) {
                        lanes.sort((a, b) => a.laneNumber > b.laneNumber ? 1 : -1);
                        state.setLanes(lanes);
                        state.setLaneGenerationLoading(false);
                    }
                }
            );
    })
    if (sectors.length === 0) {
        state.setLanes([]);
        state.setLaneGenerationLoading(false);
    }
}

export function dateToLocalizedIso(date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');

    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');

    const timezoneOffset = date.getTimezoneOffset();
    const timezoneOffsetHours = Math.abs(Math.floor(timezoneOffset / 60));
    const timezoneOffsetMinutes = Math.abs(timezoneOffset % 60);
    const timezoneSign = timezoneOffset < 0 ? '+' : '-';

    const isoString = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}${timezoneSign}${String(timezoneOffsetHours).padStart(2, '0')}:${String(timezoneOffsetMinutes).padStart(2, '0')}`;
    return isoString;
}

export const mapWorkOrderDetails = (workOrder, sectors) => {
    console.log("found sector", workOrder.sectorId, sectors.find((sector) => sector.id === workOrder.sectorId));
    let result = {
        id: workOrder.id,
        date: new Date(workOrder.createdAt),
        sector: sectors.find((sector) => sector.id === workOrder.sectorId).label,
        sectorId: workOrder.sectorId,
        lane: workOrder.laneNumber,
        vehicles: workOrder.vehicles.map((vehicle) => {
            return {
                assigned: vehicle.assigned,
                id: vehicle.id,
                vin: vehicle.vin,
                sector: vehicle.sectorLabel,
                brand: vehicle.manufacturer,
                model: vehicle.model,
                licensePlate: vehicle.licensePlate,
                laneNumber: vehicle.laneNumber,
                color: vehicle.color,
                ticked: vehicle.ticked
            }
        })
    };
    return result;
}

export const getVehicleLabel = (vehicle) => {
    if (!vehicle) return '';
    const parts = [];
    if (vehicle.vin) parts.push(vehicle.vin);
    if (vehicle.licensePlate) parts.push(vehicle.licensePlate);
    return parts.join(' - ');
}
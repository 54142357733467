<template>
  <div class="layout-header">
    <img alt="Tencara logo" class="main-logo" src="../assets/logo-tencara.svg">
    <div class="header-details">
      <p class="" v-if="appState.operation">
        Operazione:
        <v-icon :icon="mapOperationIcon(appState.operation)" color="warning"></v-icon>
        {{mapOperation(appState.operation)}}
      </p>
        <p v-if="appState.currentDDT">
          DDT:
          {{ new Intl.DateTimeFormat('it-IT', {month: '2-digit'}).format(new Date(appState.currentDDT.createdAt)) }}/{{
            appState.currentDDT.number
          }} - {{
            new Intl.DateTimeFormat('it-IT', {
              day: '2-digit',
              month: '2-digit',
              year: 'numeric'
            }).format(new Date(appState.currentDDT.createdAt))
          }} - {{ appState.currentDDT.carrier }} - {{ appState.currentDDT.carrierLicensePlate}}
        </p>
        <p v-if="appState.selectedVehicle">
            Veicolo:
            {{getVehicleLabel(appState.selectedVehicle)}}
        </p>
        <p v-if="appState.laneChoice?.sector && ['multi-move', 'realignment'].includes(appState.operation)">
            Settore:
            {{getSelectedSectorLabel()}}
        </p>
        <p v-if="appState.laneChoice?.label && ['multi-move', 'realignment'].includes(appState.operation)">
            Fila:
            {{appState.laneChoice.label}}
        </p>

        <p class="label" v-if="appState.vehicleSequence.length && appState.multipleVinSelection">
            veicoli selezionati:
        </p>
        <v-list
            v-if="appState.vehicleSequence.length && appState.multipleVinSelection"
            class="vin-list"
        >
            <v-list-item
                v-for="vehicle in appState.vehicleSequence"
                :key="vehicle.id"
                :title="getVehicleLabel(vehicle)"
            >

                <template v-slot:append>
                    <v-btn
                        color="grey-lighten-1"
                        icon="close"
                        variant="text"
                        @click="removeVin(vehicle)"
                    ></v-btn>
                </template>
            </v-list-item>
        </v-list>
    </div>
  </div>
</template>

<script>
  import {useAppState} from "@/stores/app-flow";
  import {mapOperation, mapOperationIcon} from "@/local-data/operations";
  import {getVehicleLabel} from "@/utils";

  export default {
    name: 'HeaderContent',
    props: {
    },
    setup() {
      const appState = useAppState();
      return {appState, mapOperation, mapOperationIcon, getVehicleLabel}
    },
    methods: {
        removeVin(vehicle) {
            this.appState.removeVinFromSequence(vehicle);
        },
        getSelectedSectorLabel() {
            const sector = this.appState.laneChoice?.sector;
            if (!sector) return '';
            const sectorObj = this.appState.rawSectors.find((sct) => sct.id === sector);

            return sectorObj?.label || '';
        }
    }
  }
</script>

<style lang="scss">
</style>

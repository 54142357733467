import axios from 'axios';
import {AuthService} from "@/services/auth.service";
import router from "@/router";

export const createApi = (baseURL) => {
    console.log('createApi', baseURL)
    const api = axios.create({
        baseURL
    });

    api.interceptors.request.use(config => {
        const token = localStorage.getItem('ACCESS_TOKEN');
        if (token) config.headers['Authorization'] = token;
        return config;
    }, error => {
        return Promise.reject(error)
    });

    api.interceptors.response.use(
        response => response,
        error => {
            const currentRoute = router.currentRoute.value;
            if (error.response && (error.response.status === 401 || error.response.status === 403) && !currentRoute.path.includes('/auth')) {
                console.log(error.response);
                console.log('redirect to login')
                const authService = new AuthService();
                authService.removeToken();
                authService.removeUserData();
                window.location.assign('/auth/login');
                window.location.reload();
            }
            return Promise.reject(error)
        }
    );
    return api;
};
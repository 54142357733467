<template>
    <div class="home">
        <HeaderContent></HeaderContent>
        <div class="layout-footer standard-sizes">
            <h2>Seleziona operazione:</h2>
            <v-btn
                color="blue-grey"
                prepend-icon="open_with"
                size="x-large"
                rounded="pill"
                :class="{'loading': loading && appState.operation === 'move'}"
                @click="handleOperationSelection('move')"
                :disabled="loading"
                block
            >
                spostamento
            </v-btn>
            <v-btn
                color="blue-grey"
                prepend-icon="exit_to_app"
                append-icon="workspaces"
                size="x-large"
                rounded="pill"
                :class="{'loading': loading && appState.operation === 'vehicle-exit'}"
                @click="handleOperationSelection('vehicle-exit')"
                :disabled="loading"
                block
            >
                file di carico
            </v-btn>
            <v-btn
                prepend-icon="arrow_back_ios_new"
                color="warning"
                size="x-large"
                rounded="pill"
                class="flex-grow-1"
                :disabled="loading"
                @click="backAction()"
                block
            >
                indietro
            </v-btn>
        </div>
    </div>
</template>

<script>

import {useAppState} from "@/stores/app-flow";
import HeaderContent from "@/components/HeaderContent.vue";

export default {
    name: 'OperationSelectionView',
    components: {
        HeaderContent
    },
    data: () => ({
        loading: false
    }),
    setup() {
        const appState = useAppState();
        return {appState}
    },
    methods: {
        backAction() {
            if (this.appState.flow === 2) {
                this.$router.push('/manual-vin-selection')
            } else {
                this.$router.push('/home')
            }
        },
        handleOperationSelection(type) {
            if (this.appState.flow !== 2) return this.$router.push('/home');
            this.appState.resetSectorChoice();
            this.appState.setOperation(type);

            this.$data.loading = true;
            this.$router.push('/operation-options');
        },
        handleFlowChange() {
            this.appState.resetFlow();
            this.appState.resetVin();
            this.$router.push('/home');
        }
    }
}
</script>

<style scoped>
small {
    display: block;
}
</style>

<template>
    <div class="terminal-browser">
        <div class="lists-container">

            <div v-if="!appState.sectors || appState.lanesAvailabilityLoading || appState.laneVehiclesLoading" class="list-loader">
                <v-progress-circular indeterminate></v-progress-circular>
            </div>
            <template v-if="!appState.vehicleDetails">
                <v-list v-if="!appState.sectorChoice">
                    <v-list-subheader>Settori</v-list-subheader>

                    <v-list-item
                        v-for="(item, i) in appState.sectors"
                        :key="i"
                        @click="setActiveSector(item)"
                    >
                        <v-list-item-title>{{ item.label }}</v-list-item-title>

                        <template v-slot:prepend>
                            <v-badge
                                :color="getSectorAvailabilityThemeColor(item)"
                                dot
                            ></v-badge>
                        </template>
                        <template v-slot:append>
                            <v-chip

                            >
                                <v-icon :color="getSectorAvailabilityThemeColor(item)" icon="directions_car"></v-icon>
                                <v-spacer></v-spacer>
                                {{ getSectorLaneAvailabilityString(item) }}
                            </v-chip>
                        </template>
                    </v-list-item>
                </v-list>
                <template v-if="!!appState.sectorChoice">
                    <v-list v-if="!appState.laneChoice">
                        <v-list-subheader>File</v-list-subheader>


                        <v-list-item
                            v-for="(item) in appState.lanes"
                            :key="item.uuid"
                            @click="setActiveLane(item,laneNumber)"
                        >
                            <v-list-item-title>{{ item.label }}</v-list-item-title>

                            <template v-slot:prepend>
                                <v-badge
                                    :color="getLaneAvailabilityThemeColor(item)"
                                    dot
                                ></v-badge>
                            </template>
                            <template v-slot:append>
                                <v-chip>
                                    <v-icon icon="directions_car"
                                            :color="getLaneAvailabilityThemeColor(item)"
                                    ></v-icon>
                                    <v-spacer></v-spacer>
                                    {{ getLaneAvailabilityString(item) }}
                                </v-chip>
                            </template>
                        </v-list-item>
                    </v-list>
                    <v-list v-if="!!appState.laneChoice">
                        <v-list-subheader>Veicoli</v-list-subheader>


                        <v-list-item
                            v-for="(item, i) in appState.laneVehicles"
                            :key="i"
                            @click="setActiveVehicle(item)"
                        >
                            <v-list-item-title>{{ getVehicleLabel(item) }}</v-list-item-title>
                        </v-list-item>
                    </v-list>
                </template>
            </template>
        </div>
        <div class="layout-footer standard-sizes">
            <v-text-field
                v-model="vin"
                :rules="vinRules"
                label="Vin"
                :counter="17"
                @input="vinSearch()"
                @click:clear="handleClear()"
                clearable
                required
                :disabled="loading"
                v-if="!appState.vehicleDetails"
            ></v-text-field>

            <div class="vehicle-details-container"
                 v-if="appState.vehicleDetails"
                 v-bind:class="{ 'vehicle-details-container--active': appState.vehicleDetails }">
                <div class="d-flex flex-column w-100 text-white text-left">
                    <h3>{{ appState.vehicleDetails.vin }}</h3>
                    <p v-if="appState.vehicleDetails.licensePlate">
                        Targa:
                        {{ appState.vehicleDetails.licensePlate }}
                    </p>
                    <p v-if="appState.vehicleDetails.manufacturer">
                        Costruttore:
                        {{ appState.vehicleDetails.manufacturer }}
                    </p>
                    <p v-if="appState.vehicleDetails.model">
                        Modello:
                        {{ appState.vehicleDetails.model }}
                    </p>
                    <p v-if="appState.vehicleDetails.sector">
                        Settore:
                        {{ appState.vehicleDetails.sector }}
                    </p>
                    <p v-if="appState.vehicleDetails.laneNumber">
                        Fila:
                        {{ appState.vehicleDetails.laneNumber }}
                    </p>
                </div>
            </div>

            <div class="d-flex justify-start align-center footer-bottom-controls">
                <v-btn
                    icon="arrow_back_ios_new"
                    color="warning"
                    class="btn_icon"
                    @click="goBack()"
                ></v-btn>
<!--                <span class="ml-2">{{getListHeading()}}</span>-->
                <div class="flex-grow-1 d-flex justify-end">
                    <template v-if="appState.sectorChoice && !appState.laneChoice">
                        <v-chip>
                            <v-icon icon="directions_car"
                                    :color="getSectorAvailabilityThemeColor(appState.sectorChoice)"
                            ></v-icon>
                            <v-spacer></v-spacer>
                            {{ getSectorLaneAvailabilityString(appState.sectorChoice) }}
                        </v-chip>
                    </template>
                    <template v-if="appState.laneChoice">
                        <v-chip>
                            <v-icon icon="directions_car"
                                    :color="getLaneAvailabilityThemeColor(appState.laneChoice)"
                            ></v-icon>
                            <v-spacer></v-spacer>
                            {{ getLaneAvailabilityString(appState.laneChoice) }}
                        </v-chip>
                    </template>
                </div>
            </div>

            <div class="layout-details"
                 v-if="(appState.vinSearchLoading || appState.vinSearchResults.length || searchTriggered)">
                <div class="d-flex justify-center align-center details-loader" v-if="appState.vinSearchLoading">
                    <v-progress-circular
                        model-value="20"
                        color="red"
                        indeterminate
                    >
                    </v-progress-circular>
                </div>
                <div class="vin-search-results-list-container" v-if="appState.vinSearchResults.length > 0">
                    <h2>{{ appState.vinSearchResults.length }}
                        {{ appState.vinSearchResults.length > 1 ? 'Risultati' : 'Risultato' }}:</h2>
                    <ul>
                        <li v-for="(result, index) in appState.vinSearchResults" v-bind:key="index" @click="setActiveVehicle(result)">
                            {{ getVehicleLabel(result) }}
                        </li>
                    </ul>
                </div>
                <div class="d-flex justify-center align-center h-100 w-100 text-white"
                     v-if="appState.vinSearchResults.length === 0">
                    <h3>Nessun veicolo trovato</h3>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {useAppState} from "@/stores/app-flow";
import {generateLanes, getVehicleLabel, validateVin} from "@/utils";

export default {
    name: "TerminalBrowser",
    components: {},
    data() {
        return {
            item: null,
            sectors: [],
            vin: '',
            lanesLoading: false,
            debounceTimerTimestamp: 0,
            loading: false,
            searchTriggered: false,
            vinRules: [
                value => {
                    if (value) return true;
                    const appState = useAppState();

                    return appState.vinFieldReset ? true : 'Vin richiesto.'
                },
                value => {
                    const isValidVin = validateVin(value);
                    if (isValidVin) return true;
                    const appState = useAppState();

                    return appState.vinFieldReset ? true : 'Vin non valido.'
                }
            ]
        };
    },
    setup() {
        const appState = useAppState();

        return {appState, getVehicleLabel}
    },
    inject: ['coreService'],
    async mounted() {
        await this.appState.fetchSectors(true);
    },
    methods: {
        setActiveSector(sector) {
            this.appState.setSectorChoice(sector.id);
            this.lanesLoading = true;
            const sectors = sector.type === 'group' ? sector.sectors : [sector];
            generateLanes(sectors, this.appState, this.coreService);
            this.appState.fetchSectorLanesAvailability(sectors.map((sector) => sector.id));
        },
        getSectorAvailabilityThemeColor(sector) {
            const availability = this.getSectorLaneAvailability(sector);
            if (availability.filled === 0) {
                return 'green-accent-3';
            } else if (availability.filled < availability.total) {
                return 'yellow-darken-1';
            } else if (availability.filled === availability.total) {
                return 'red-accent-3';
            } else {
                return 'secondary';
            }
        },
        getLaneAvailabilityThemeColor(lane) {
            if (this.appState.lanesAvailabilityLoading) return 'secondary';
            const availability = this.getLaneAvailability(lane);
            if (availability.filled === 0) {
                return 'green-accent-3';
            } else if (availability.filled < availability.total) {
                return 'yellow-darken-1';
            } else {
                return 'red-accent-3';
            }
        },
        getSectorLaneAvailabilityString(sector) {
            if (!sector) return '';
            if (typeof sector === 'string') sector = this.appState.sectors.concat(this.appState.rawSectors).find((rawSector) => rawSector.id === sector);
            if (sector.groupId) sector = this.appState.sectors.concat(this.appState.rawSectors).find((rawSector) => rawSector.id === sector.groupId);
            const availability = this.getSectorLaneAvailability(sector);
            return availability.filled + ' / ' + availability.total;
        },
        getLaneAvailabilityString(lane) {
            const availability = this.getLaneAvailability(lane);
            return availability.filled + ' / ' + availability.total;
        },
        getLaneAvailability(lane) {
            if (!lane) return '';
            const sector = this.appState.rawSectors.find((rawSector) => rawSector.id === lane.sector);
            if (!sector) return '';
            const vCount = this.appState.lanesAvailabilityLoading ? '_' :
                this.appState.lanesAvailability.find((availability) => availability.laneNumber === lane.laneNumber)?.vehicleCount || 0;
            const vCap = sector.laneLength;
            return {filled: vCount, total: vCap};
        },
        getSectorLaneAvailability(sector) {
            if (!sector) return '';
            if (typeof sector === 'string') sector = this.appState.sectors.concat(this.appState.rawSectors).find((rawSector) => rawSector.id === sector);
            if (sector.groupId) sector = this.appState.sectors.concat(this.appState.rawSectors).find((rawSector) => rawSector.id === sector.groupId);
            const sInstances = sector.type === 'group' ? sector.sectors : [sector];
            let vCount = 0;
            let vCap = 0;
            sInstances.forEach((innerSector) => {
                vCount += innerSector.vehicleCount;
                vCap += innerSector.laneCount * innerSector.laneLength;
            })
            if (!vCount && !vCap) return null;
            return {filled: vCount, total: vCap};
        },
        handleClear() {
            this.vin = "";
            this.appState.resetVinSearchResults();
            this.searchTriggered = false;
        },
        vinSearch() {
            if (this.vin.length > 17) return this.vin = this.vin.substring(0, 17)
            this.debounceTimerTimestamp = Date.now() + 1000;
            this.appState.setVinFieldReset(true);
            setTimeout(() => {
                if (this.debounceTimerTimestamp < Date.now() && this.vin.length > 2) {
                    this.searchTriggered = true;
                    this.appState.vinSearch(this.vin);
                }
            }, 1010)
        },
        setActiveVehicle(item) {
            if (!item) return;
            const state = this.appState;
            state.setVinFieldReset(true);
            this.handleClear();
            if (typeof item === 'string') {
                const vehicle = state.vehicles.find((vehicle) => vehicle.vin === item);
                if (vehicle) state.setVehicleDetails(vehicle);
                return;
            }
            state.setVehicleDetails(item);
        },
        getListHeading() {
            if (!this.appState.sectorChoice) return 'Settori';
            let sector = this.appState.sectors.concat(this.appState.rawSectors).find((sector) => sector.id === this.appState.sectorChoice);
            if (sector?.groupId) sector = this.appState.sectors.concat(this.appState.rawSectors).find((innerSector) => innerSector.id === sector.groupId);
            if (!this.appState.laneChoice) return 'Settore ' + sector.label;
            if (!this.appState.vehicleDetails) return ('Settore ' + sector?.label || '') + ' - Fila ' + this.appState.laneChoice.label;
        },
        setActiveLane(lane) {
            this.appState.setLaneChoice(lane);
            this.appState.fetchLaneVehicles(lane);
        },
        goBack() {
            const state = this.appState;
            if (state.vehicleDetails) {
                if (state.vehicleDetails.laneNumber && state.vehicleDetails.sectorId) {
                    let sector = this.appState.sectors.concat(this.appState.rawSectors).find((innerSector) => innerSector.id === state.vehicleDetails.sectorId);
                    if (sector?.groupId) sector = this.appState.sectors.concat(this.appState.rawSectors).find((innerSector) => innerSector.id === sector.groupId);
                    this.setActiveSector(sector);
                    state.setLaneChoice({
                        sector: state.vehicleDetails.sectorId,
                        label: state.vehicleDetails.laneNumber,
                        laneNumber: state.vehicleDetails.laneNumber
                    });
                    state.fetchLaneVehicles(state.laneChoice);
                }
                state.setVehicleDetails(null);
                return;
            } else if (state.laneChoice) {
                const sector = this.appState.sectors.concat(this.appState.rawSectors).find((innerSector) => innerSector.id === state.laneChoice.sector);
                let sectors = [];
                if (sector?.type === 'group') sectors = sector.sectors;
                else sectors = [sector];
                generateLanes(sectors, this.appState, this.coreService);
                state.fetchSectorLanesAvailability(sectors.map((innerSector) => innerSector.id));

                state.setLaneChoice(null);
                return;
            } else if (state.sectorChoice) {
                state.setSectorChoice(null);
                state.fetchSectors(true);
                return;
            }

            this.$router.push("/home")
        }
    },
};
</script>
<template>
    <v-list-item
        :class="'work-order-vehicle-list-item d-flex justify-space-between align-end ' + (vehicle.assigned ? 'assigned' : '')"
    >
        <v-list-item-title>
            {{ getVehicleLabel(vehicle) }}
        </v-list-item-title>

        <v-list-item-subtitle>
            {{ vehicleBrandModel }}
        </v-list-item-subtitle>

        <v-list-item-subtitle class="text-alt-accent" v-if="vehicle.assigned">
            Assegnato
        </v-list-item-subtitle>

        <template v-slot:append v-if="!edit">
            <v-chip v-if="vehicleLoading && appState.workOrderMoveMode && !vehicleMoveTarget">
                <v-icon
                    class="remove_spacing"
                    icon="location_on"
                    color="orange-lighten-1"
                ></v-icon>
            </v-chip>
            <v-progress-circular indeterminate v-else-if="vehicleLoading && appState.workOrderMoveMode && vehicleMoveTarget"></v-progress-circular>
            <v-chip v-else-if="!vehicle.ticked">
                {{ vehiclePosition }}
                <v-spacer></v-spacer>
                <v-icon
                    icon="location_on"
                        color="orange-lighten-1"
                ></v-icon>
            </v-chip>
            <v-icon icon="check_circle"
                    color="green-accent-3"
                    v-else
            ></v-icon>
        </template>
        <template v-slot:append v-else>
            <v-btn
               rounded="pill"
               :append-icon="!toBeRemoved ? 'close' : 'undo'"
               :color="!toBeRemoved ? 'blue-grey' : 'warning'"
               @click="toggleToBeRemoved()"
            >{{ !toBeRemoved ? 'rimuovi' : 'annulla' }}</v-btn>
        </template>
    </v-list-item>
</template>

<script>
import {useAppState} from "@/stores/app-flow";
import {getVehicleLabel} from "@/utils";

export default {
    name: 'WorkOrderVehicleListItem',
    props: {
        vehicle: {
            type: Object,
            required: true
        },
        edit: {
            type: Boolean,
            required: false,
            default: false
        },
    },
    setup() {
        const appState = useAppState();
        return {appState, getVehicleLabel}
    },
    computed: {
        toBeRemoved() {
            return this.appState.workOrderVinRemovalCandidates.includes(this.vehicle.id);
        },
        vehicleLoading() {
            if(this.vehicle.ticked) return false;
            if(!this.appState.workOrderMoveMode) return false;
            return true;
        },
        vehicleMoveTarget() {
            return this.vehicle.vin.toUpperCase() === this.appState.workOrderMoveModeTarget && this.appState.workOrderMoveModeLoading;
        },
        vehiclePosition() {
            const parts = [];
            if (this.vehicle.sector) parts.push(this.vehicle.sector);
            if (this.vehicle.laneNumber) parts.push(this.vehicle.laneNumber);

            return parts.join(' - ');
        },
        vehicleBrandModel() {
            const parts = [];
            if (this.vehicle.brand) parts.push(this.vehicle.brand);
            if (this.vehicle.model) parts.push(this.vehicle.model);
            let model = parts.join(' ');
            if (this.vehicle.color) model += ' (' + this.vehicle.color + ')';
            return model;
        }
    },
    methods: {
        toggleToBeRemoved() {
            console.log(this.vehicle)
            this.appState.toggleWorkOrderVinRemovalCandidate(this.vehicle.id);
        },
    }
}
</script>

<style lang="scss">
</style>

import {createApi} from "@/services/api";

export class AuthService {
    api;
    constructor(baseURL) {
        this.api = createApi(baseURL);
    }
    getUserData() {
        return JSON.parse(localStorage.getItem("USER_DATA"));
    }
    getToken() {
        return localStorage.getItem("ACCESS_TOKEN");
    }
    saveToken(token) {
        localStorage.setItem("ACCESS_TOKEN", token);
    }
    saveUserData(userData) {
        localStorage.setItem("USER_DATA", JSON.stringify(userData));
    }
    removeToken() {
        localStorage.removeItem("ACCESS_TOKEN");
    }
    removeUserData() {
        localStorage.removeItem("USER_DATA");
    }
    isAuthenticated() {
        return !!this.getToken();
    }
    login(email, password) {
        return this.api.post('login', {
            eMailAddress: email,
            password: password
        })
        .then(async (res) => {
            this.saveLoginResponse(await res.data);
        });
    }

    async logout() {
        try {
            await this.api.post('logout');
            this.removeToken();
            this.removeUserData();
        } catch (e) {
            console.error(e);
        }
    }
    async resetPassword(eMailAddress) {
        return await this.api.post('reset-password', {
            eMailAddress: eMailAddress
        })
    }
    async changePassword(newPassword) {
        return await this.api.post('change-password', {
            newPassword: newPassword
        });
    }
    async autoLogin(token) {
        const response = await this.api.post('auto-login', {token});
        if (response.status >= 400) throw new Error(response.statusText);
        this.saveLoginResponse(response);
    }

    saveLoginResponse(response) {
        this.saveToken(response.token);
        this.saveUserData(response.user);
    }
}
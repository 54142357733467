import {useAppState} from "@/stores/app-flow";
import {dateToLocalizedIso} from "@/utils";
import {createApi} from "@/services/api";

export class CoreService {
    api;
    constructor(baseURL) {
        this.api = createApi(baseURL);
    }
    submitVehicleOperation(vin, operation, options = {}) {
        return this.api.put('vehicles/' + vin + '/location', options)
        .catch(err => {
            const appState = useAppState();
            console.log(err);
            appState.addNotification({
                type: 'ERROR',
                operation: operation || appState.operation,
                vin: appState.vin,
            });
        });
    }

    setWorkOrderVehicleAssigned(status, id, orderId) {
        return this.api.put('/orders/' + orderId + '/vehicles/' + id + '/assigned', status.toString(), {
            headers: {
                'Content-Type': 'application/json'
            }
        })
    }

    setVehicleArrivalDate(vin, date) {
        const appState = useAppState();
        return this.api.put('vehicles/' + vin + '/arrived-at', {
            arrivedAt: dateToLocalizedIso(new Date(date)),
            arrivedBy: appState.selectedTransportType
        })
        .catch(err => {
            console.log(err)
            appState.addNotification({
                type: 'ERROR',
                description: 'Errore'
            });
        });
    }

    vinSearch(term) {
        return this.api.get('vehicles?search=' + term)
            .then(res => {
                console.log(res)
                return res.data;
            })
            .catch(err => {
                console.log(err)
            });
    }

    fetchSectors(outbound= false) {
        return this.api.get((outbound ? 'outbound' : 'parking') + '-sectors')
            .then(res => {
                console.log(res)
                return res.data;
            })
            .catch(err => {
                console.log(err)
            });
    }

    fetchWorkOrders(pageNumber = 1) {
        return this.api.get('orders?PageSize=10&PageNumber=' + pageNumber)
            .then(res => {
                console.log(res)
                return res.data;
            })
            .catch(err => {
                console.log(err)
            });
    }

    getWorkOrder(id) {
        return this.api.get('orders/' + id)
            .then(res => {
                console.log(res)
                return res.data;
            })
            .catch(err => {
                console.log(err)
            });
    }

    updateWorkOrder(id, data) {
        return this.api.put('orders/' + id, data)
        .then(res => {
            console.log(res)
            return res.data;
        })
        .catch(err => {
            console.log(err)
        });
    }

    removeWorkOrderVehicle(id, vehicleId) {
        return this.api.delete('orders/' + id + '/vehicles/'+ vehicleId)
        .then(res => {
            return res;
        })
        .catch(err => {
            console.log(err)
        });
    }

    fetchAllSectorsWithDetails() {
        return this.api.get('sectors?includeDetails=true')
            .then(res => {
                console.log(res)
                return res.data;
            })
            .catch(err => {
                console.log(err)
            });
    }

    fetchAllDDT(pageNumber = 1) {
        return this.api.get('transport-documents?PageSize=10&PageNumber=' + pageNumber)
            .then(res => {
                console.log(res);
                return res.data;
            })
            .catch(err => {
                console.log(err);
            });
    }

    fetchSingleDDT(id) {
        return this.api.get('transport-documents/' + id)
            .then(res => {
                console.log(res);
                return res.data;
            })
            .catch(err => {
                console.log(err);
            });
    }

    sendDDTSign(ddt_id, sign_file_blob) {
        const form = new FormData();
        form.append("file", sign_file_blob);
        return this.api.put('/transport-documents/' + ddt_id + '/signature', form, {
            headers: {
                'Content-Type': 'multipart/form-data'
            },
        }).then(res => {
            console.log(res)
            return res.data;
        }).catch(err => {
            console.log(err)
        });
    }

    fetchSectorGroups() {
        return this.api.get('groups')
            .then(res => {
                console.log(res)
                return res.data;
            })
            .catch(err => {
                console.log(err)
            });
    }

    markLaneVehiclesAsLost(sectorId, laneNumber) {
        return this.api.put('sectors/' + sectorId + '/lanes/' + laneNumber + '/are-lost', "true", {
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .catch(err => {
                const appState = useAppState();
                console.log(err)
                appState.addNotification({
                    type: 'ERROR',
                    description: 'Errore'
                });
            });
    }

    fetchSectorAnomalies(sectorId) {
        return this.api.get('sectors/' + sectorId + '/anomalies')
            .then(res => {
                console.log(res)
                return res.data;
            })
            .catch(err => {
                console.log(err)
            });
    }

    fetchSectorLanes(sectorId) {
        return this.api.get('sectors/' + sectorId + '/lanes')
            .then(res => {
                console.log(res)
                return res.data;
            })
            .catch(err => {
                console.log(err)
            });
    }

    fetchLaneVehicles(sectorId, laneId) {
        return this.api.get('sectors/' + sectorId + '/lanes/' + laneId)
            .then(res => {
                console.log(res)
                return res.data;
            })
            .catch(err => {
                console.log(err)
            });
    }
}
const operations = {
    'vehicle-exit': {
        label: 'File di carico',
        icon: 'exit_to_app'
    },
    'move': {
        label: 'Spostamento',
        icon: 'open_with'
    },
    'realignment': {
        label: 'Riallineamento fila',
        icon: 'question_mark'
    },
    'multi-move': {
        label: 'Censimento',
        icon: 'library_add'
    },
    'work-orders': {
        label: 'Ordini di uscita',
        icon: 'library_add'
    },
    'sign-ddt': {
        label: 'Firma DDT',
        icon: 'draw'
    }
};
export function mapOperation(val) {
    if (!operations[val]) return '';

    return operations[val].label;
}
export function mapOperationIcon(val) {
    if (!operations[val]) return '';

    return operations[val].icon;
}
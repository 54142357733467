<template>
    <div class="work-order-details">
        <div class="details-container">
            <v-progress-circular v-if="appState.workOrderDetailsLoading" indeterminate></v-progress-circular>
            <v-list v-if="!appState.workOrderDetailsLoading">

                <v-list-subheader>Destinazione</v-list-subheader>
                <v-list-item :title="workOrderDestination">
                    <template v-if="editMode" v-slot:append>
                        <v-btn
                            icon="edit"
                            rounded="pill"
                            variant="text"
                            v-on:click="dialogOpen()"
                        ></v-btn>
                    </template>
                </v-list-item>
                <v-divider></v-divider>

                <v-list-subheader>Veicoli</v-list-subheader>
                <WorkOrderVehicleListItem v-for="vehicle in appState.workOrderDetails.vehicles" v-bind:key="vehicle.vin"
                                          :edit="editMode" :vehicle="vehicle" @click="handleAssigned(vehicle)"></WorkOrderVehicleListItem>
            </v-list>
        </div>
        <div class="layout-footer standard-sizes">


            <div v-if="hasUnticked && !editMode" class="d-flex justify-space-between">
                <v-btn
                    v-if="!appState.workOrderMoveMode"
                    :class="{'flex-grow-1': true}"
                    color="warning"
                    prepend-icon="qr_code"
                    rounded="pill"
                    size="x-large"
                    @click="toggleWorkOrderMoveMode()"
                >
                    Scansiona veicoli
                </v-btn>
                <v-btn
                    v-else
                    :class="{'flex-grow-1': true}"
                    append-icon="workspaces"
                    color="warning"
                    rounded="pill"
                    size="x-large"
                    @click="toggleWorkOrderMoveMode()"
                >
                    Annulla
                </v-btn>
            </div>

            <div v-if="editMode" class="d-flex justify-space-between">
                <v-btn
                    :class="{'flex-grow-1': true}"
                    color="warning"
                    prepend-icon="save"
                    rounded="pill"
                    size="x-large"
                    @click="saveAction()"
                >
                    Salva
                </v-btn>
            </div>

            <div class="d-flex justify-start align-center footer-bottom-controls">
                <v-btn
                    :disabled="editMode"
                    class="btn_icon"
                    color="warning"
                    icon="arrow_back_ios_new"
                    @click="goBack()"
                ></v-btn>
                <span class="ml-2">{{ screenHeading }}</span>
                <v-btn
                    :icon="editMode ? 'close' : 'edit'"
                    class="btn_icon ml-auto"
                    color="warning"
                    @click="editModeToggle()"
                ></v-btn>
            </div>
        </div>
    </div>
    <v-fade-transition hide-on-leave>
        <v-card
            v-if="dialog"
            class="mx-auto popup-edit-destination"
            title="Seleziona destinazione"
        >

            <v-divider></v-divider>

            <div class="py-12 px-4">

                <div v-if="appState.operation !== 'vehicle-received'" class="loadable-select">
                    <span></span>
                    <v-progress-circular
                        v-if="appState.sectorsLoading"
                        color="red"
                        indeterminate
                        model-value="20"></v-progress-circular>
                    <v-select
                        v-model="appState.sectorChoice"
                        :disabled="appState.sectorsLoading || loading"
                        :items="appState.sectors"
                        item-props="itemProps"
                        item-title="label"
                        item-value="id"
                        label="Settore"
                    ></v-select>
                </div>

                <div v-if="appState.operation !== 'vehicle-received'" class="loadable-select">
                    <span></span>
                    <v-progress-circular
                        v-if="appState.lanesLoading"
                        color="red"
                        indeterminate
                        model-value="20"></v-progress-circular>
                    <v-autocomplete
                        v-model="appState.laneChoice"
                        :disabled="initialised && (appState.lanesLoading || !appState.sectorChoice || loading)"
                        :items="appState.lanes"
                        item-title="label"
                        label="Fila"
                        return-object
                    ></v-autocomplete>
                </div>
            </div>

            <v-divider></v-divider>

            <div class="pa-4 text-end">
                <v-btn
                    class="text-none mr-2"
                    color="warning"
                    min-width="92"
                    rounded
                    :disabled="disablePopupSubmit"
                    @click="savePopupInput()"
                >
                    Conferma
                </v-btn>
                <v-btn
                    class="text-none"
                    color="medium-emphasis"
                    min-width="92"
                    rounded
                    variant="outlined"
                    @click="dialog = false"
                >
                    Chiudi
                </v-btn>
            </div>
        </v-card>
    </v-fade-transition>
</template>

<script>
import {useAppState} from "@/stores/app-flow";
import {useRoute} from "vue-router";
import WorkOrderVehicleListItem from "@/components/WorkOrderVehicleListItem.vue";
import moment from "moment/moment";
import {generateLanes, getVehicleLabel} from "@/utils";
import {inject} from "vue";

export default {
    name: "WorkOrderDetails",
    components: {
        WorkOrderVehicleListItem
    },
    data() {
        return {
            editMode: false,
            dialog: false,
            loading: false,
            initialised: false,
            editValues: {
                sector: null,
                lane: null,
                sectorId: null,
            },
        };
    },
    setup() {
        const appState = useAppState();
        const coreService =  inject('coreService');
        const route = useRoute();
        const workOrderId = route.params.id;
        appState.fetchWorkOrderDetails(workOrderId);
        return {appState, coreService, getVehicleLabel}
    },
    computed: {
        screenHeading() {
            if (!this.appState.workOrderDetailsLoading && this.appState.workOrderDetails) {
                return moment(this.appState.workOrderDetails.date)
                    .format('DD/MM/y');
            }
            return 'Dettagli ordine di uscita'
        },
        workOrderDestination() {
            if (!!this.appState.workOrderDetailsLoading || !this.appState.workOrderDetails) return '';
            if (!!this.editMode && !!this.editValues.sector && !!this.editValues.lane) {
                return `${this.editValues.sector} - ${this.editValues.lane}`;
            }
            const parts = [];
            const workOrder = this.appState.workOrderDetails;
            if (workOrder.sector) parts.push(workOrder.sector);
            if (workOrder.lane) parts.push(workOrder.lane);
            return parts.join(' - ');
        },
        hasUnticked() {
            if (!this.appState.workOrderDetails || !this.appState.workOrderDetails.vehicles) return false;
            const foundVehicles = this.appState.workOrderDetails.vehicles.filter(v => !v.ticked);
            return foundVehicles.length > 0;
        },
        sectorChoice() {
            return JSON.stringify(this.appState.sectorChoice);
        },
        disablePopupSubmit() {
            return this.loading || !this.appState.laneChoice || !this.appState.sectorChoice;
        }
    },
    props: {},
    mounted() {
        this.appState.workOrderMoveMode = false;
        this.appState.setOperation("work-orders");
    },
    watch: {
        sectorChoice(newValue) {
            if (!newValue) {
                this.appState.setLanes([]);
                this.appState.resetLaneChoice();
            } else {
                this.handleSectorChange(newValue);
            }
        }
    },
    methods: {
        goBack() {
            this.appState.currentWorkOrder = null;
            this.$router.push('/work-orders');
        },
        dialogOpen() {
            this.fetchSectors();
            this.dialog = true;
        },
        editModeToggle() {
            if (!this.editMode) {
                this.editValues = {
                    sector: this.appState.workOrderDetails.sector,
                    lane: this.appState.workOrderDetails.lane,
                    sectorId: this.appState.workOrderDetails.sectorId,
                }
            }
            this.editMode = !this.editMode;
        },
        toggleWorkOrderMoveMode() {
            this.appState.workOrderMoveMode = !this.appState.workOrderMoveMode;
            this.checkWorkOrderMoveAbility();
        },
        checkWorkOrderMoveAbility() {
            if (!this.hasUnticked) this.appState.workOrderMoveMode = false;
        },
        fetchSectors() {
            this.appState.fetchSectors(false);
        },
        handleSectorChange(newValue) {
            newValue = newValue.replaceAll("\"", '')
            const sector = this.appState.sectors.find((sector) => {
                return sector.id == newValue
            });

            if (sector?.label.toLowerCase() === 'jolly') {
                this.commentsEnabled = true;
            } else {
                this.commentsEnabled = false;
            }

            let sectors = [sector];
            if (sector?.type === 'group') {
                sectors = sector.sectors
            }
            this.appState.resetLaneChoice();

            this.generateLanes(sectors);
        },
        generateLanes(sectors) {
            generateLanes(sectors, this.appState, this.coreService);
            this.appState.fetchSectorLanesAvailability(sectors.map((sector) => sector.id));
        },
        savePopupInput() {
            const foundSector = this.appState.sectors.find((sector) => {
                if (sector.type === 'group') {
                    return !!sector.sectors.find((subSector) => {
                        return subSector.id === this.appState.laneChoice.sector;
                    });
                }
                return sector.id === this.appState.laneChoice.sector;
            });
            if (!foundSector) return;
            this.editValues.sector = foundSector.label;
            this.editValues.sectorId = this.appState.laneChoice.sector;
            this.editValues.lane = this.appState.laneChoice.laneNumber;
            this.appState.resetLaneChoice();
            this.dialog = false;
        },
        async saveAction() {
            this.appState.workOrderDetails.sectorId = this.editValues.sectorId;
            this.appState.workOrderDetails.lane = this.editValues.lane;
            await this.appState.updateWorkOrderDetails();
            this.editMode = false;
        },
        async handleAssigned(vehicle) {
            if (this.editMode) return;
            if (this.appState.workOrderMoveMode) {
                return;
            }
            this.appState.workOrderDetailsLoading = true;
            await this.appState.handleAssigned(vehicle).then(() => {
                this.appState.workOrderDetailsLoading = false;
                const vehicleFound = this.appState.workOrderDetails.vehicles.find(v => v.vin === vehicle.vin);
                vehicleFound.assigned = !vehicle.assigned;
            });

        },
        moveVehicle(vehicle) {
            this.appState.moveWorkOrderVehicle(vehicle);
            this.checkWorkOrderMoveAbility();
        },
    },
};
</script>
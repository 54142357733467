import {createApp} from 'vue'
import App from './App.vue'
import router from './router'
import 'material-design-icons-iconfont/dist/material-design-icons.css'
import {createVuetify} from 'vuetify';
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import * as styles from 'vuetify/styles';
import {aliases, md} from 'vuetify/iconsets/md'
import {createPinia} from 'pinia'
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import {configService} from "@/services/configuration.service";

async function main() {
    const config = (await configService.getConfig()).data;
    console.log('config', config)
    const vuetify = createVuetify({
        components,
        directives,
        styles,
        icons: {
            defaultSet: 'md',
            aliases,
            sets: {
                md,
            },
        }
    });
    const pinia = createPinia();
    const vueApp = createApp(App);
    vueApp.use(router);
    vueApp.use(pinia);
    vueApp.use(vuetify);
    vueApp.component('VueDatePicker', VueDatePicker);
    // Provide the configuration data
    vueApp.provide('$config', config);
    vueApp.mount('#app');
}

main();
<template>
    <v-list-item
        class="work-order-list-item d-flex justify-space-between align-end"
        @click="goToWorkOrderDetails(workOrder)"
    >
        <v-list-item-title>
            {{ getLabel(workOrder) }}
        </v-list-item-title>

        <v-list-item-subtitle>
            {{ workOrderDestination }}
        </v-list-item-subtitle>
        <template v-slot:prepend>
        </template>
        <template v-slot:append>
            <v-chip>
                <v-icon icon="directions_car"
                        :color="workOrder.ticked ? 'green-accent-3' : 'orange-lighten-1'"
                ></v-icon>
                <v-spacer></v-spacer>
                {{ workOrder.vehicles.length }}
            </v-chip>
        </template>
    </v-list-item>
</template>

<script>
import {useAppState} from "@/stores/app-flow";
import moment from "moment";

export default {
    name: 'WorkOrderListItem',
    props: {
        workOrder: {
            type: Object,
            required: true
        }
    },
    computed: {
        workOrderDestination() {
            const parts = [];
            if (this.workOrder.sector) parts.push(this.workOrder.sector);
            if (this.workOrder.lane) parts.push(this.workOrder.lane);

            return parts.join(' - ');
        }
    },
    setup() {
        const appState = useAppState();
        return {appState}
    },
    methods: {
        goToWorkOrderDetails() {
            this.appState.currentWorkOrder = this.workOrder;
            this.$router.push('work-orders/' + this.workOrder.id);
        },
        getLabel(workOrder) {
            console.log('workOrder', workOrder);
            return moment(workOrder.date).format('DD/MM/y');
        }
    }
}
</script>

<style lang="scss">
</style>

import { createRouter, createWebHashHistory } from 'vue-router'
import OperationSelectionView from '../views/OperationSelectionView.vue'
import VinRetrievalModeSelection from "@/views/VinRetrievalModeSelection.vue";
import HomeView from "@/views/HomeView.vue";
import OperationOptions from "@/views/OperationOptions.vue";
import BarcodeScannerOutput from "@/views/BarcodeScannerOutput.vue";
import TerminalBrowser from "@/views/TerminalBrowser.vue";
import WorkOrdersList from "@/views/WorkOrdersList.vue";
import WorkOrderDetails from "@/views/WorkOrderDetails.vue";
import AuthenticationView from "@/views/AuthenticationView.vue";
import ForgotPasswordView from "@/views/ForgotPasswordView.vue";

const routes = [
  {
    path: '/',
    name: 'empty',
    redirect: '/auth/login',
  },
  {
    path: '/home',
    name: 'home',
    component: HomeView
  },
  {
    path: '/auth/login',
    name: 'authentication',
    component: AuthenticationView
  },
  {
    path: '/auth/forgot-password',
    name: 'forgot-password',
    component: ForgotPasswordView
  },
  {
    path: '/operation-selection-view',
    name: 'operation-selection-view',
    component: OperationSelectionView
  },
  {
    path: '/operation-options',
    name: 'operation-options',
    component: OperationOptions
  },
  {
    path: '/barcode-scanner/output',
    name: 'barcode-scanner-output',
    component: BarcodeScannerOutput
  },
  {
    path: '/vin-mode-selection',
    name: 'vin-mode-selection',
    component: VinRetrievalModeSelection
  },
  {
    path: '/terminal-browser',
    name: 'terminal-browser',
    component: TerminalBrowser
  },
  {
    path: '/work-orders',
    name: 'work-orders',
    component: WorkOrdersList
  },
  {
    path: '/work-orders/:id',
    name: 'work-order-details',
    component: WorkOrderDetails
  },
  {
    path: '/scanner',
    name: 'scanner',
    component: () => import('../views/CodeReader.vue')
  },
  {
    path: '/manual-vin-selection',
    name: 'manual-vin-selection',
    component: () => import('../views/ManualVinSelectionView.vue')
  },
  {
    path: '/ddt-list',
    name: 'ddt-list',
    component: () => import('../views/DDTList.vue')
  },
  {
    path: '/ddt-sign/:id',
    name: 'ddt-sign',
    component: () => import('../views/DDTSign.vue')
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

router.beforeEach((to) => {
  console.log(to.path);
  if (to.path.includes('auth')) return true;
  if (localStorage.getItem("ACCESS_TOKEN")) return true;
  router.push('/auth/login');
  return false;
})

export default router

<template>
    <div class="home">
        <HeaderContent></HeaderContent>
        <div class="layout-footer standard-sizes">
            <h2>Vin:</h2>
            <p class="vin-output">{{ appState.vin }}</p>
            <div class="d-flex justify-space-between">
                <v-btn
                    icon="arrow_back_ios_new"
                    color="warning"
                    class="btn_icon"
                    @click="backAction()"
                ></v-btn>
                <v-btn
                    color="warning"
                    size="x-large"
                    rounded="pill"
                    append-icon="workspaces"
                    @click="handleViewAction()"
                    :class="{'flex-grow-1': true}"
                >
                    Continua
                </v-btn>
            </div>
        </div>
    </div>
</template>

<script>
import {useAppState} from "@/stores/app-flow";
import HeaderContent from "@/components/HeaderContent.vue";
import {inject} from "vue";

export default {
    name: "BarcodeScannerOutput",
    components: {
        HeaderContent
    },
    data() {
        return {
            text: "",
            codes: [],
            id: null,
            modalOpen: false,
            sectors: [],
            sectorValue: null,
            laneValue: null
        };
    },
    props: {
        msg: String,
    },
    setup() {
        const appState = useAppState();
        const coreService =  inject('coreService');
        return {appState, coreService}
    },
    methods: {
        handleViewAction() {
            this.appState.setVin(this.text);
            if (this.appState.operation) {
                this.$router.push('/operation-options');
                this.appState.setFlow(1)
            } else {
                if (!this.appState.vin) {
                    this.$router.push('/home');
                    return;
                }
                this.appState.setFlow(2)
                this.$router.push('/operation-selection-view');
            }
        },
        backAction() {
            this.appState.resetVin();
            this.$router.back();
        }
    },
};
</script>
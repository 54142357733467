<template>
    <div class="notifications-manager">
        <div
            :class="{
                'notification': true,
                '_success': n.type === 'SUCCESS',
                '_error': n.type === 'ERROR'
            }"
            v-for="(n) in activeNotifications"
            v-bind:key="n.id"
        >
            <template v-if="n.type === 'SUCCESS'">
                <span v-if="n.description">
                    {{n.description}}
                </span>
                <span v-else>
                <v-icon :icon="mapOperationIcon(n.operation)"></v-icon>
                <strong>{{mapOperation(n.operation)}}</strong>
                <span v-if="n.vin">
                    per
                    <strong>{{n.vin}}</strong>
                </span>
                completato.
                </span>
            </template>
            <template v-if="n.type === 'ERROR'">
                <span v-if="n.operation">
                    Errore nella comunicazione col server<br>
                    <v-icon :icon="mapOperationIcon(n.operation)"></v-icon>
                    <strong>{{mapOperation(n.operation)}}</strong>
                    per
                </span>
                <span v-if="n.description">
                    {{n.description}}
                </span>
                <strong v-if="n.vin">
                    <br v-if="n.description">
                    {{n.vin}}
                </strong>.
            </template>
        </div>
    </div>
</template>

<script>
import {useAppState} from "@/stores/app-flow";
import {mapOperation, mapOperationIcon} from "@/local-data/operations";

export default {
    name: 'NotificationsManager',
    props: {
    },
    data: () => {
      return {
          activeNotifications: []
      }
    },
    setup() {
        const appState = useAppState();

        return {appState, mapOperation, mapOperationIcon}
    },
    computed: {
        notifications() {
            return JSON.stringify(this.appState.notifications);
        },
    },
    watch: {
        notifications(newValue) {
            newValue = JSON.parse(newValue);
            if (newValue.length) {
                this.activeNotifications.push(newValue[0]);
                this.appState.removeNotification(parseInt(newValue[0].id));
                setTimeout(() => {
                    this.removeActiveNotification(parseInt(newValue[0].id));
                }, 3000)
            }
        }
    },
    methods: {
        addNotification() {
            this.appState.addNotification({
                type: 'ERROR',
                operation: 'vehicle-exit',
                vin: 'QWEQWE123123123EW'
            })
        },
        removeActiveNotification(id) {
            const index = this.activeNotifications.findIndex((notification) => notification.id === id);
            if (index < 0) return;
            this.activeNotifications.splice(index, 1);
        }
    }
}
</script>

<style lang="scss">
</style>

<template>
    <v-btn icon="person" class="floating-user-btn" v-if="user">
        <v-icon>person</v-icon>
        <v-menu activator="parent">
            <v-list>
                <v-list-item
                    prepend-icon="person"
                >
                    {{ user }}
                </v-list-item>
                <v-list-item
                    prepend-icon="logout"
                    @click="logout"
                >
                    Logout
                </v-list-item>
            </v-list>
        </v-menu>
    </v-btn>
</template>

<script>
import {useAppState} from "@/stores/app-flow";
import {inject} from "vue";

export default {
    name: 'UserPanel',
    props: {
    },
    data() {
        return {
            drawer: false
        }
    },
    setup() {
        const appState = useAppState();
        const authService =  inject('authService');
        return {appState, authService}
    },
    computed: {
        user() {
            const data = this.authService.getUserData();
            console.log(data);
            if (!data) return '';
            const parts = [];
            if (data.firstName) parts.push(data.firstName);
            if (data.lastName) parts.push(data.lastName);
            return parts.join(' ');
        }
    },
    methods: {
        logout() {
            this.authService.logout()
                .then(() => {
                    window.location.assign('/auth/login');
                    window.location.reload();
                })
        }
    }
}
</script>

<style lang="scss">
</style>

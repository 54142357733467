import {defineStore} from 'pinia'
import {getVehicleLabel, mapWorkOrderDetails, validateVin} from "@/utils";
import {configService} from "@/services/configuration.service";
import {CoreService} from "@/services/core.service";
import router from "@/router";

export const useAppState = defineStore('app-state', {
    state: () => {
        return {
            coreService: null,
            operation: null,
            vin: null,
            licensePlate: null,
            selectedVehicle: null,
            flow: null,
            notifications: [],
            notificationIdLocal: 0,
            sectors: [],
            rawSectors: [],
            groups: [],
            lanes: [],
            selectedSector: null,
            selectedLane: null,
            sectorsLoading: false,
            ddtLoading: false,
            ddtListPage: 1,
            ddtListPageTotal: 1,
            ddtList: [],
            currentDDT: null,
            selectedTransportType: null,
            transportTypes: [
                {
                    label: 'Bisarca',
                    value: 'Truck'
                },
                {
                    label: 'Treno',
                    value: 'Rail'
                },
                {
                    label: 'Nave',
                    value: 'Ship'
                }
            ],
            lanesLoading: false,
            vinSearchLoading: false,
            laneChoice: null,
            sectorChoice: null,
            vehicles: [],
            vinSearchResults: [],
            inputSequence: '',
            workOrders: [],
            workOrderDetails: null,
            workOrdersLoading: false,
            workOrderDetailsLoading: false,
            workOrdersPage: 1,
            workOrdersPagesTotal: 1,
            currentWorkOrder: null,
            workOrderMoveMode: false,
            workOrderMoveModeTarget: null,
            workOrderMoveModeLoading: null,
            workOrderVinRemovalCandidates: [],
            inputSequenceLastUpdate: 0,
            multipleVinSelection: false,
            hasLaneConflict: false,
            vinFieldReset: false,
            vinSequence: [],
            vehicleSequence: [],
            vehicleArrivedAt: null,
            vehicleHasArrived: false,
            vehicleHasArrivedBy: false,
            checkingSequenceValidity: false,
            lanesAvailability: [],
            lanesAvailabilityLoading: false,
            laneVehicles: [],
            laneVehiclesLoading: false,
            laneGenerationLoading: false,
            vehicleDetails: null,
            allSectors: [],
            comment: null,
        }
    },
    actions: {
        setVehicleDetails(newValue) {
            this.vehicleDetails = newValue
        },
        async addInputValue(value) {
            if (this.checkingSequenceValidity) return;
            if (Date.now() - this.inputSequenceLastUpdate > 200) {
                this.inputSequence = value;
            } else {
                this.inputSequence += value;
            }
            if (validateVin(this.inputSequence)) {
                const coreService = await this.getCoreService();
                const originalString = this.inputSequence.toLowerCase();
                this.checkingSequenceValidity = true;
                coreService.vinSearch(this.inputSequence)
                    .then((result) => {
                        this.vehicles = result.rows;
                        result = result.rows;
                        this.checkingSequenceValidity = false;
                        const index = result.findIndex((vehicle) => vehicle.vin.toLowerCase() === originalString)
                        if (index > -1) {
                            if (this.multipleVinSelection) {
                                const added = this.addVinToSequence(result[index]);
                                if (added) this.addNotification({
                                    type: 'SUCCESS',
                                    description: originalString + ' aggiunto alla lista',
                                });
                            } else if (this.operation === "work-orders") {
                                if (!this.workOrderMoveMode) return;
                                const found = this.workOrderDetails.vehicles.find((vehicle) => vehicle.vin.toUpperCase() === originalString.toUpperCase());
                                if (found) {
                                    this.workOrderMoveModeTarget = originalString.toUpperCase();
                                    this.moveWorkOrderVehicle(found);
                                }
                                else {
                                    this.addNotification({
                                        type: 'ERROR',
                                        description: 'Telaio non presente nel ordine',
                                        vin: this.inputSequence.toUpperCase(),
                                    });
                                }
                            } else {
                                this.selectedVehicle = result[index];
                                this.vin = originalString.toUpperCase();
                                this.checkVinArrivalDate();
                                this.checkVinArrivedBy();

                                router.push('/barcode-scanner/output');
                            }
                        } else {
                            this.addNotification({
                                type: 'ERROR',
                                description: 'Numero telaio sconosciuto',
                                vin: this.inputSequence.toUpperCase(),
                            });
                        }
                    });
            }
            this.inputSequenceLastUpdate = Date.now();
        },
        addNotification(newValue) {
            const newestValue = Object.assign(newValue, {id: this.notificationIdLocal})
            this.notificationIdLocal++;
            this.notifications.push(newestValue);
        },
        async handleAssigned(vehicle) {
            if (!this.workOrderDetails) return;
            const coreService = await this.getCoreService();
            await coreService.setWorkOrderVehicleAssigned(!vehicle.assigned, vehicle.id, this.workOrderDetails.id);
        },
        removeNotification(id) {
            const index = this.notifications.findIndex((notification) => notification.id === id);
            if (index < 0) return;
            this.notifications.splice(index, 1);
        },
        async moveWorkOrderVehicle(vehicle) {
            const coreService = await this.getCoreService();
            const options = {
                "sectorId": this.workOrderDetails.sectorId,
                "laneNumber":  this.workOrderDetails.lane
            };
            this.workOrderMoveModeLoading = true;
            return coreService.submitVehicleOperation(vehicle.id, 'move', options)
                .then((result) => {
                    if (result.status < 300) {
                        this.addNotification({
                            type: 'SUCCESS',
                            description: 'Veicolo ' + getVehicleLabel(vehicle) + ' spostato con successo'
                        });
                        const index = this.workOrderDetails.vehicles.indexOf(vehicle);
                        if (index > -1) {
                            this.workOrderDetails.vehicles[index].ticked = true;
                        }
                    }
                    this.workOrderMoveModeLoading = false;
                });
        },
        setOperation(newValue) {
            this.operation = newValue;
        },
        setComment(newValue) {
            this.comment = newValue;
        },
        setVinFieldReset(newValue) {
            this.vinFieldReset = newValue
        },
        setLaneConflict(value) {
            this.hasLaneConflict = value;
        },
        setVin(newValue) {
            const isValidVin = validateVin(newValue);
            if (isValidVin) {
                this.vin = newValue;
                this.checkVinArrivalDate();
                this.checkVinArrivedBy();
            }
        },
        addVinToSequence(value) {
            const vIndex = this.vehicleSequence.findIndex((vin) => vin.id === value.id);
            if (vIndex > -1) return false;

            this.vehicleSequence.push(value);
            this.resetVin();
            return true;
        },
        checkVinArrivalDate() {
            const found = this.selectedVehicle;
            if (found && found.arrivedAt) {
                this.vehicleArrivedAt = new Date(found.arrivedAt);
                this.vehicleHasArrived = true;
            } else {
                this.vehicleArrivedAt = null;
                this.vehicleHasArrived = false;
            }
        },
        checkVinArrivedBy() {
            const found = this.selectedVehicle;
            if (found && found.arrivedBy) {
                this.selectedTransportType = found.arrivedBy;
                this.vehicleHasArrivedBy = true;
            } else {
                this.selectedTransportType = null;
                this.vehicleHasArrivedBy = false;
            }
        },
        removeVinFromSequence(value) {
            const vIndex = this.vehicleSequence.findIndex((vin) => vin.id === value.id);
            if (vIndex < 0) return;

            this.vehicleSequence.splice(vIndex, 1);
        },
        emptyVinSequence() {
            this.vehicleSequence = [];
        },
        setMultipleVinSelections(newValue) {
            this.multipleVinSelection = newValue;
        },
        async vinSearch(term) {
            this.vinSearchLoading = true;
            const coreService = await this.getCoreService();
            return coreService.vinSearch(term)
                .then((result) => {
                    console.log("vin search result", result);
                    this.vehicles = result.rows;
                    // result = result.rows.map((vehicle) => vehicle.vin);
                    this.vinSearchLoading = false;
                    this.vinSearchResults = result.rows;
                });
        },
        async fetchWorkOrders() {
            this.workOrdersLoading = true;
            const coreService = await this.getCoreService();
            const fetchWorkOrdersAction = (sectors) => {
                coreService.fetchWorkOrders(this.workOrdersPage)
                    .then((result) => {
                        this.workOrders = result.rows.map((workOrder) => {
                            return {
                                id: workOrder.id,
                                date: new Date(workOrder.createdAt),
                                sector: sectors.find((sector) => sector.id === workOrder.sectorId).label,
                                lane: workOrder.laneNumber,
                                ticked: workOrder.ticked,
                                vehicles: workOrder.vehicles.map((vehicle) => {
                                    return {
                                        id: vehicle.id,
                                        vin: vehicle.vin,
                                        sector: vehicle.sectorLabel,
                                        brand: vehicle.manufacturer,
                                        model: vehicle.model,
                                        laneNumber: vehicle.laneNumber,
                                        color: vehicle.color
                                    }
                                })
                            };
                        })
                        this.workOrdersPagesTotal = result.pageCount;
                        this.workOrdersLoading = false;
                    });
            }
            if (this.allSectors.length > 0) {
                fetchWorkOrdersAction(this.allSectors);
            } else {
                coreService.fetchAllSectorsWithDetails()
                    .then((sectors) => {
                        this.allSectors = sectors;
                        fetchWorkOrdersAction(sectors);
                });
            }
        },
        toggleWorkOrderVinRemovalCandidate(vin) {
            const index = this.workOrderVinRemovalCandidates.findIndex((candidate) => candidate === vin);
            if (index > -1) {
                this.workOrderVinRemovalCandidates.splice(index, 1);
            } else {
                this.workOrderVinRemovalCandidates.push(vin);
            }
        },
        async fetchWorkOrderDetails(id) {
            this.workOrderDetailsLoading = true;
            const coreService = await this.getCoreService();
            const getWorkOrderAction = (sectors) => {
                coreService.getWorkOrder(id)
                    .then((result) => {
                        this.workOrderDetailsLoading = false;
                        const workOrder = result;
                        this.workOrderDetails = mapWorkOrderDetails(workOrder, sectors);
                    });
            }
            if (this.allSectors.length > 0) {
                getWorkOrderAction(this.allSectors);
            } else {
                coreService.fetchAllSectorsWithDetails()
                    .then((sectors) => {
                        this.allSectors = sectors;
                        getWorkOrderAction(sectors);
                    });
            }
        },
        async updateWorkOrderDetails() {
            this.workOrderDetailsLoading = true;
            const coreService = await this.getCoreService();
            const options = {
                "sectorId": this.workOrderDetails.sectorId,
                "laneNumber":  this.workOrderDetails.lane
            };
            const vehicleQueues = [];
            if (this.workOrderVinRemovalCandidates.length > 0) {
                for(const vId of this.workOrderVinRemovalCandidates) {
                    vehicleQueues.push(coreService.removeWorkOrderVehicle(this.workOrderDetails.id, vId))
                }
                this.workOrderVinRemovalCandidates = [];
            }
            Promise.all(vehicleQueues)
                .then(() => {
                    coreService.updateWorkOrder(this.workOrderDetails.id, options)
                        .then((result) => {
                            this.workOrderDetailsLoading = false;
                            this.workOrderDetails = mapWorkOrderDetails(result, this.sectors);
                            this.addNotification({
                                type: 'SUCCESS',
                                description: 'Ordine di uscita aggiornato con successo'
                            });
                        });
                })
        },
        setSectorChoice(newValue) {
            this.sectorChoice = newValue;
        },
        resetSectorChoice() {
            this.laneChoice = null;
            this.sectorChoice = null;
        },
        async fetchSectorLanesAvailability(sectors) {
            const coreService = await this.getCoreService()
            let count = 0;
            this.lanesAvailability = [];
            this.lanesAvailabilityLoading = true;
            const tryClose = () => {
                ++count;
                if (count === sectors.length) {
                    this.lanesAvailabilityLoading = false;
                    this.lanes.forEach((lane) => {
                        const found = this.lanesAvailability.find((availability) => availability.laneNumber === lane.laneNumber);
                        if (found && found.note) {
                            lane.label = lane.label + ' - ' + found.note;
                        } else {
                            lane.label = lane.laneNumber;
                        }
                    });
                }
            }
            sectors.forEach((sector) => {
                coreService.fetchSectorLanes(sector)
                    .then((result) => {
                        this.lanesAvailability = this.lanesAvailability.concat(result);
                        tryClose();
                    })
            })
        },
        setLaneGenerationLoading(newValue) {
            this.laneGenerationLoading = newValue;
        },
        async fetchLaneVehicles(lane) {
            const coreService = await this.getCoreService()
            this.laneVehiclesLoading = true;
            coreService.fetchLaneVehicles(lane.sector, lane.laneNumber)
                .then((result) => {
                    this.laneVehiclesLoading = false;
                    this.laneVehicles = result;
                });
        },
        setLaneChoice(newValue) {
            this.laneChoice = newValue;
        },
        resetLaneChoice() {
            this.laneChoice = null;
        },
        resetVinSearchResults() {
            this.vinSearchLoading = false;
            this.vinSearchResults = [];
        },
        async fetchSectors(fetchAll = false) {
            const coreService = await this.getCoreService()
            this.sectorsLoading = true;
            this.sectors = [];
            this.sectorChoice = null;
            this.laneChoice = null;
            this.groups = await coreService.fetchSectorGroups();
            const fetchAction = fetchAll ? coreService.fetchAllSectorsWithDetails() : coreService.fetchSectors(['vehicle-exit', 'work-orders'].includes(this.operation))
            return fetchAction
                .then((result) => {
                    this.rawSectors = result;
                    this.sectorsLoading = false;
                    const parsedSectors = {};
                    const endValues = [];
                    const pushSector = (sector) => {
                        if (sector.groupId) return;
                        if (!sector.laneCount && !result.find((innerSector) => innerSector.parentId === sector.id)) return;
                        if (!sector.parentId) {
                            if (parsedSectors[sector.id]) parsedSectors[sector.id].item = sector;
                            else parsedSectors[sector.id] = {item: sector, children: [], type: 'sector'};
                        } else {
                            if (parsedSectors[sector.parentId]) parsedSectors[sector.parentId].children.push(sector);
                            else parsedSectors[sector.parentId] = {item: null, children: [sector], type: 'sector'};
                        }
                    }
                    result.forEach(pushSector)
                    this.groups.forEach((group) => {
                        const groupMembers = result.filter((innerSector) => innerSector.groupId === group.id);
                        if (groupMembers && groupMembers.length) {
                            parsedSectors[group.id] = {item: group, sectors: groupMembers, type: 'group'}
                        }
                    });
                    Object.values(parsedSectors).forEach((sector) => {
                        if (sector.children) {
                            endValues.push(Object.assign(sector.item, {type: 'sector'}));
                            if (sector.children.length) sector.item = Object.assign(sector.item, {itemProps: {disabled: true}});
                            sector.children.sort((a, b) => a.label > b.label ? 1 : -1);
                            sector.children.forEach((innerSector) => {
                                innerSector.label = sector.item.label + ' - ' + innerSector.label + ' ' + (innerSector.numerationType !== 'Even' ? innerSector.numerationType !== 'Odd' ? '' : '(dispari)' : '(pari)');
                                endValues.push(Object.assign(sector.item, {type: 'sector'}));
                            });
                        } else {
                            endValues.push(Object.assign(sector.item, {type: 'group', sectors: sector.sectors}));
                        }
                    });
                    endValues.sort((a, b) => a.order > b.order ? 1 : -1);
                    this.sectors = endValues;
                });
        },
        async fetchDDTList() {
            const coreService = await this.getCoreService()
            this.ddtLoading = true;
            const result = await coreService.fetchAllDDT(this.ddtListPage);
            this.ddtList = result.rows;
            this.ddtListPageTotal = result.pageCount;
            this.ddtLoading = false;
        },
        async fetchDDT(id) {
            const coreService = await this.getCoreService();
            this.setOperation('sign-ddt');
            this.ddtLoading = true;
            this.currentDDT = await coreService.fetchSingleDDT(id);
            this.ddtLoading = false;
        },
        async sendDDTSign(ddt_id, sign_png) {
            const coreService = await this.getCoreService();
            this.ddtLoading = true;
            try {
                const base64Response = await fetch(sign_png);
                const sign_png_blob = await base64Response.blob();
                coreService.sendDDTSign(ddt_id, sign_png_blob);
                this.addNotification(
                    {
                        type: 'SUCCESS',
                        description: 'Documento firmato con successo!',
                    });
                this.ddtLoading = false;
                return true;
            } catch (error) {
                this.ddtLoading = false;
                return false;
            }
        },
        async setLanes(lanes) {
            this.lanes = lanes;
        },
        setFlow(newValue) {
            this.flow = newValue;
        },
        resetOperation() {
            this.operation = null;
        },
        resetVin() {
            this.vin = null;
        },
        resetFlow() {
            this.flow = null;
        },
        async getCoreService() {
            if (!this.coreService) {
                const config = await configService.getConfig();
                this.coreService = new CoreService(config.data.baseApiUrl);
            }
            return Promise.resolve(this.coreService);
        }
    },
})
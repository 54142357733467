<template>
  <div class="home">
    <HeaderContent></HeaderContent>
    <div class="layout-footer standard-sizes">
      <h2>Modalità d'identificazione:</h2>
      <router-link to="/scanner" class="list_v">
        <v-btn
            color="blue-grey"
            prepend-icon="qr_code_scanner"
            size="x-large"
            rounded="pill"
            block
        >
          Scanner
        </v-btn>
      </router-link>
      <v-btn
          color="blue-grey"
          prepend-icon="data_array"
          size="x-large"
          rounded="pill"
          block
          disabled
      >
        lettore vin
      </v-btn>
      <div class="d-flex justify-space-between">
        <v-btn
            icon="arrow_back_ios_new"
            color="warning"
            class="btn_icon"
            @click="handleBackAction()"
        ></v-btn>
        <router-link
            to="/manual-vin-selection"
            class="flex-grow-1 d-flex"
        >
          <v-btn
              color="warning"
              prepend-icon="text_fields"
              size="x-large"
              rounded="pill"
              class="flex-grow-1"
          >
            Manuale
          </v-btn>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>

import HeaderContent from "@/components/HeaderContent.vue";
import {useAppState} from "@/stores/app-flow";

export default {
  name: 'VinRetrievalModeSelection',
  components: {
        HeaderContent
  },
  setup() {
        const appState = useAppState();
        return {appState}
  },
  methods: {
    handleBackAction() {
        this.appState.resetOperation();
        this.appState.resetFlow();
        this.$router.push("/home");
    }
  }
}
</script>

<style scoped>
</style>
